import React from 'react'
import { About, Footer, Header, Skills, Testimonial,  Work } from './container'
import { Navbar } from './components'
import './App.scss'

const App = () => {
  return (
    <div className="app">
      <Navbar />
      <Header />
      <About />
      <Work />
      <Skills />
      <Testimonial />
      <Footer />
      {/*<div style={{width: "100vw",
                   height: "100vh",
                   display:"flex", 
                   flexDirection: "column",
                   alignItems:"center",
                   justifyContent: "center",
                   color: "#b9b0f7"}}>
        <div style={{ textAlign: "center"}}>
        <h1>Site down for maintenance</h1> 
        <h3 style={{color: "black"}}>Please stop by another time!</h3>
        </div>
  </div>*/}
    </div>
  )
}

export default App